import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import {
  GetPolicyParams,
  PolicyCreate,
  PolicyDetails,
  PolicyLog,
  PremiumAdjustmentLogParams
} from '@core/models/interfaces/lead/lead-policys';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  baseUrl = `${environment.API_URL}/crm/policies`;
  logBaseUrl = `${environment.API_URL}/crm/premium-adjustment-logs`;

  constructor(private http: HttpClient) {}

  getPolicies(params?: GetPolicyParams) {
    let queryParams = new HttpParams();

    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params?.leadIds !== undefined) {
      for (let id of params?.leadIds) {
        queryParams = queryParams.append('leadIds', id);
      }
    }

    if (params?.statusIds !== undefined) {
      for (let id of params?.statusIds) {
        queryParams = queryParams.append('statusIds', id);
      }
    }

    if (params?.ownerIds !== undefined) {
      for (let id of params?.ownerIds) {
        queryParams = queryParams.append('ownerIds', id);
      }
    }

    if (params?.agentIds !== undefined) {
      for (let id of params?.agentIds) {
        queryParams = queryParams.append('agentIds', id);
      }
    }

    if (params?.companyIds !== undefined) {
      for (let id of params?.companyIds) {
        queryParams = queryParams.append('companyIds', id);
      }
    }

    if (params?.productTypeIds !== undefined) {
      for (let id of params?.productTypeIds) {
        queryParams = queryParams.append('productTypeIds', id);
      }
    }

    if (params?.commissionOwnerIds !== undefined) {
      for (let id of params?.commissionOwnerIds) {
        queryParams = queryParams.append('commissionOwnerIds', id);
      }
    }

    if (params?.policyTrackingIds !== undefined) {
      for (let id of params?.policyTrackingIds) {
        queryParams = queryParams.append('policyTrackingIds', id);
      }
    }

    if (params?.modeIds !== undefined) {
      for (let id of params?.modeIds) {
        queryParams = queryParams.append('modeIds', id);
      }
    }

    if (params?.productIds !== undefined) {
      for (let id of params?.productIds) {
        queryParams = queryParams.append('productIds', id);
      }
    }

    if (params?.updateTypes !== undefined) {
      for (let id of params?.updateTypes) {
        queryParams = queryParams.append('updateTypes', id);
      }
    }

    if (params?.createdByIds !== undefined) {
      for (let id of params?.createdByIds) {
        queryParams = queryParams.append('createdByIds', id);
      }
    }

    if (params?.statusDetailIds !== undefined) {
      for (let id of params?.statusDetailIds) {
        queryParams = queryParams.append('statusDetailIds', id);
      }
    }

    if (params?.effectiveFrom !== undefined) {
      queryParams = queryParams.append('effectiveFrom', params.effectiveFrom);
    }

    if (params?.effectiveTo !== undefined) {
      queryParams = queryParams.append('effectiveTo', params.effectiveTo);
    }

    if (params?.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params?.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    if (params?.showHide !== undefined) {
      queryParams = queryParams.append('showHide', params.showHide);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return this.http.get<ResponseListApi<PolicyDetails>>(this.baseUrl, {
      params: queryParams
    });
  }

  getPolicyById(id: string) {
    return this.http.get<ResponseApi<PolicyDetails>>(`${this.baseUrl}/${id}`);
  }

  createPolicy(body: PolicyCreate) {
    return this.http.post<ResponseApi<PolicyDetails>>(this.baseUrl, body);
  }

  updatePolicyById(id: string, body: PolicyCreate) {
    return this.http.put<ResponseApi<PolicyDetails>>(`${this.baseUrl}/${id}`, body);
  }

  hidePolicyById(id: string, hide: boolean) {
    let params = new HttpParams();
    if (hide) {
      params = params.set('hide', hide);
    }
    return this.http.put(`${this.baseUrl}/${id}/hide`, null, { params });
  }

  getPremiumAdjustmentLogs(params?: PremiumAdjustmentLogParams) {
    let queryParams = new HttpParams();

    if (params?.policyId !== undefined) {
      queryParams = queryParams.append('policyId', params.policyId);
    }

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }

    if (params?.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }

    if (params?.sort !== undefined) {
      queryParams = queryParams.append('sorts', params.sort);
    }

    return this.http.get<ResponseListApi<PolicyLog>>(this.logBaseUrl, {
      params: { ...params }
    });
  }

  updateStatusPolicy(id: string, statusId: string) {
    return this.http.put(`${this.baseUrl}/${id}/status?statusId=${statusId}`, null);
  }

  exportPolicy(params?: GetPolicyParams) {
    let queryParams = new HttpParams();

    if (params?.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }

    if (params?.leadIds !== undefined) {
      for (let id of params?.leadIds) {
        queryParams = queryParams.append('leadIds', id);
      }
    }

    if (params?.statusIds !== undefined) {
      for (let id of params?.statusIds) {
        queryParams = queryParams.append('statusIds', id);
      }
    }

    if (params?.ownerIds !== undefined) {
      for (let id of params?.ownerIds) {
        queryParams = queryParams.append('ownerIds', id);
      }
    }

    if (params?.agentIds !== undefined) {
      for (let id of params?.agentIds) {
        queryParams = queryParams.append('agentIds', id);
      }
    }

    if (params?.companyIds !== undefined) {
      for (let id of params?.companyIds) {
        queryParams = queryParams.append('companyIds', id);
      }
    }

    if (params?.productTypeIds !== undefined) {
      for (let id of params?.productTypeIds) {
        queryParams = queryParams.append('productTypeIds', id);
      }
    }

    if (params?.commissionOwnerIds !== undefined) {
      for (let id of params?.commissionOwnerIds) {
        queryParams = queryParams.append('commissionOwnerIds', id);
      }
    }

    if (params?.policyTrackingIds !== undefined) {
      for (let id of params?.policyTrackingIds) {
        queryParams = queryParams.append('policyTrackingIds', id);
      }
    }

    if (params?.modeIds !== undefined) {
      for (let id of params?.modeIds) {
        queryParams = queryParams.append('modeIds', id);
      }
    }

    if (params?.productIds !== undefined) {
      for (let id of params?.productIds) {
        queryParams = queryParams.append('productIds', id);
      }
    }

    if (params?.updateTypes !== undefined) {
      for (let id of params?.updateTypes) {
        queryParams = queryParams.append('updateTypes', id);
      }
    }

    if (params?.createdByIds !== undefined) {
      for (let id of params?.createdByIds) {
        queryParams = queryParams.append('createdByIds', id);
      }
    }

    if (params?.statusDetailIds !== undefined) {
      for (let id of params?.statusDetailIds) {
        queryParams = queryParams.append('statusDetailIds', id);
      }
    }

    if (params?.effectiveFrom !== undefined) {
      queryParams = queryParams.append('effectiveFrom', params.effectiveFrom);
    }

    if (params?.effectiveTo !== undefined) {
      queryParams = queryParams.append('effectiveTo', params.effectiveTo);
    }

    if (params?.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }

    if (params?.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }

    if (params?.showHide !== undefined) {
      queryParams = queryParams.append('showHide', params.showHide);
    }

    return this.http.get(`${this.baseUrl}/export`, {
      params: queryParams,
      responseType: 'blob'
    });
  }
}
